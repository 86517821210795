import './scripts/publicPath'
import 'console-polyfill'
import 'normalize.css/normalize.css'
import './main.scss'
import $ from 'jquery'
import feather from 'feather-icons'
import installCE from 'document-register-element/pony'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

// var debounce = require('lodash/debounce')
// import 'objectFitPolyfill'
// https://www.npmjs.com/package/objectFitPolyfill

window.jQuery = $

window.lazySizesConfig = window.lazySizesConfig || {}
window.lazySizesConfig.preloadAfterLoad = true
window.lazySizesConfig.expFactor = 4
require('lazysizes')

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger)
gsap.defaults({ ease: 'none' })

$(document).ready(function () {
  feather.replace({
    'stroke-width': 1
  })

  // Intro + blockquote rainbows
  gsap.utils.toArray('.intro').forEach((item) => {
    ScrollTrigger.create({
      trigger: item,
      once: true,
      start: '-100px center',
      // markers: true,
      onEnter: () => {
        item.classList.add('active')
      }
    })
  })
  gsap.utils.toArray('blockquote').forEach((item) => {
    ScrollTrigger.create({
      trigger: item,
      once: true,
      start: '-100px center',
      // markers: true,
      onEnter: () => {
        item.classList.add('active')
      }
    })
  })

  // Generic Scrolltrigger animation classes
  gsap.utils.toArray('[data-animated]').forEach((item) => {
    const start = $(item).data('animated-start') !== undefined ? $(item).data('animated-start') : '100px bottom'
    ScrollTrigger.create({
      trigger: item,
      once: true,
      start: start,
      // markers: true,
      onEnter: () => {
        item.classList.add('active')
      }
    })
  })

  // Fixed header
  ScrollTrigger.create({
    trigger: $('#mainContent'),
    start: '+=100',
    end: 'bottom+=1000',
    // endTrigger: '.mainFooter',
    toggleClass: { targets: 'body', className: 'on-scroll' }
  })

  // Hands animation
  // gsap.utils.toArray('[data-animated-home-lines]').forEach((item) => {
  //   var $line1 = $('.svgLine1', item)
  //   var $line2 = $('.svgLine2', item)
  //   var $line3 = $('.svgLine3', item)

  //   // gsap.set($image, { duration: 0, delay: 0, opacity: 1 })

  //   // prepare SVG paths
  //   $line1 = pathPrepare($line1)
  //   $line2 = pathPrepare($line2)
  //   $line3 = pathPrepare($line3)

  //   // build tween
  //   gsap.timeline({
  //     paused: true,
  //     scrollTrigger: {
  //       trigger: item,
  //       start: 'top center',
  //       // markers: true,
  //       once: true,
  //       onEnter: () => item.classList.add('active')
  //     }
  //   })
  //     .add(gsap.to($line1, { duration: 3, strokeDashoffset: 0, ease: 'power1.out' }), 0)
  //     .add(gsap.to($line2, { duration: 3, strokeDashoffset: 0, ease: 'power1.out' }), 0.25)
  //     .add(gsap.to($line3, { duration: 3, strokeDashoffset: 0, ease: 'power1.out' }), 0.5)
  // })

  // Reverse path in html - https://codepen.io/enxaneta/pen/WWPYqQ
  // function pathPrepare ($el) {
  //   var lineLength = $el[0].getTotalLength()
  //   $el.css('stroke-dasharray', lineLength)
  //   $el.css('stroke-dashoffset', lineLength)
  //   return $el
  // }
})

installCE(window, {
  type: 'force',
  noBuiltIn: true
})

function importAll (r) {
  r.keys().forEach(r)
}

importAll(require.context('../Components/', true, /\/script\.js$/))
