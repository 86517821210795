import $ from 'jquery'
import 'readmore-js'

class BlockTextAccordian extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)

    $('.post-copy').readmore({
      speed: 100,
      collapsedHeight: 0,
      moreLink: '<div class="read-container"><a href="#" class="read-link read-more"></a></div>',
      lessLink: '<div class="read-container"><a href="#" class="read-link read-less"></a></div>',
      beforeToggle: function (trigger, element, expanded) {
        // add and remove open class from parent
        if (expanded) {
          $($(trigger).parent('.post-content').removeClass('is-open'))
        } else {
          $($(trigger).parent('.post-content').addClass('is-open'))
        }
      }
    })

    $('.post-title').on('click', function () {
      console.log('title clicked')
      $(this).siblings('.post-copy').readmore('toggle')
    })

    // $('article:nth-of-type(3)').readmore('toggle');
  }
}

window.customElements.define('block-text-accordian', BlockTextAccordian, { extends: 'div' })
