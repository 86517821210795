import $ from 'jquery'
import Flickity from 'flickity'

class BlockTestimonials extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.resolveElements()
  }

  resolveElements () {
    this.$slider = $('.slider', this)

    // Flickity options, defaults
    var options = {
      // imagesLoaded: true,
      // percentPosition: false,
      // cellAlign: 'left',
      draggable: true,
      autoPlay: 5000,
      // contain: true,
      wrapAround: true,
      prevNextButtons: false,
      pageDots: false
      // freeScroll: true
    }

    // eslint-disable-next-line no-unused-vars
    const carousel = new Flickity(this.$slider[0], options)
    // console.log(carousel)
  }
}

window.customElements.define('block-testimonials', BlockTestimonials, {
  extends: 'div'
})
